export function getLanguage(language) {
    if (language === "fr")
        return French()
    return English()
}

function French() {
    return {
        language : 'fr',
        home : 'Accueil',
        projects : 'Projets',
        currentProject : 'Projet en cours',
        progress : 'Etat d\'avancement',
        noPage : 'Cette page n\'existe pas. Veuillez vérifier l\'url et réessayer.',
        dev : 'Développeur',
        signIn : 'Connexion',
        logout : 'Déconnexion',
        signUp : 'Inscription',
        contact : 'Informations de contact',
        created : 'Créé le ',
        poll : 'Sondage',
        pollAsk : 'Que devrais-je faire ensuite ?',
        suggest : 'Une idée à proposer ?',
        suggestHead : 'Vous avez une idée de projet à me proposer ? Ou un défi à me lancer ? Ou un commentaire à faire sur ce site ? C\'est ici que ça se passe.',
        suggestTitle : 'Un titre accrocheur',
        suggestDetails : 'Un concept génial',
        suggestSend : 'Proposer',
        suggestLoggedOut : 'Vous devez vous connecter pour soumettre une idée',
        alreadySuggested : 'Vous avez déjà proposé quelque chose aujourd\'hui',
        chatOn : 'Dites un truc sympa',
        chatOff : 'Connectez-vous pour utiliser le chat',
        bio1 : 'Mon nom est Gilles Poncelet. Et ceci est mon site Web.',
        bio2 : 'Je suis né le 13 janvier 1989 à Etterbeek, en Belgique.',
        bio3 : 'Enfant de parents artistes, je me suis moi-même intéressé très tôt à la musique et au théâtre.',
        bio4 : 'Après mes études secondaires, je me suis naturellement dirigé vers le Conservatoire Royal de Bruxelles, où j\'ai obtenu un master en théatre et arts de la parole, que j\'ai complété avec une agrégation.',
        bio5 : 'En 2017, je créais ma propre compagnie de théâtre, que j\'ai codirigée pendant 3 ans.',
        bio6 : 'Pendant cette période, j\'ai notamment créé un site web pour la compagnie, à l\'aide des outils proposés par WordPress. Mais je sentais bien que je pourrais faire mieux si je comprenais les rouages de la création de sites web.',
        bio7 : 'C\'est ainsi que, quelques temps après avoir quitté la compagnie, et alors que je cherchais ce que j\'allais pouvoir faire ensuite, l\'idée me fut glissée par un ami que je devrais peut-être me plonger vraiment dans le code.',
        bio8 : 'En 2022, j\'intégrai donc l \'école 19, à Bruxelles. J\'y ai passé 2 ans à apprendre le code.',
        bio9 : 'A la toute fin du programme de l\'école, j\'ai eu l\'occasion de me faire la main sur un projet web.',
        bio10 : 'Et à la fin de l\'été suivant, le site que vous avez sous les yeux était né. Faisant usage des mêmes technologies que lors du fameux projet web de l\'école, j\'ai pu - et peux encore car on peut toujours faire mieux - laisser libre cours à ma créativité.',
        noProject : 'Ce projet n\'existe pas. Vérifiez l\'url et réessayez.',
        seeOnGH : 'Voir sur GitHub',
        username : 'Nom d\'utilisateur',
        password : 'Mot de passe',
        passwordConfirm : 'Confirmation du mot de passe',
        connexion : 'Se connecter',
        signInError : 'Nom d\'utilisateur ou mot de passe incorrect',
        createAccount : 'Créer un compte',
        allFieldsMandatory : 'Tous les champs sont obligatoires',
        noAccount : 'Pas de compte',
        signUpError_1 : 'Le format de l\'adresse e-mail est incorrect',
        signUpError_2 : 'Les mots de passe ne correspondent pas',
        signUpError_3 : 'Un compte associé à cette adresse e-mail existe déjà',
        signUpError_4 : 'Ce nom d\'utilisateur n\'est pas disponible',
        signUpError_5 : 'Tous les champs sont obligatoires',
        signUpError_6 : 'Une erreur s\'est produite lors de la création du compte',
        signUpError_7 : 'Une erreur s\'est produite lors de l\'authentification',
        signUpError_8 : 'Une erreur s\'est produite',
        signInError_1 : 'Vous êtes déjà connecté',
        signInError_2 : 'Nom d\'utilisateur ou mot de passe incorrect',
        signInError_3 : 'Une erreur s\'est produite',
        hello : 'Bonjour,',
        profile : 'Profil',
        goAway : 'Tu n\'as rien à faire là.',
        suggestionSent : 'Suggestion envoyée',
        suggestionNotSent_1 : 'Vous devez être connecté pour faire une suggestion',
        suggestionNotSent_2 : 'Vous avez déjà une suggestion en cours',
        mailMe : "M'envoyer un e-mail",
        displayWidgets : 'Afficher les widgets',
        invalidUsername : "Nom d'utilisateur invalide",
        invalidPW : 'Mot de passe invalide',
        nameRegex : "Caractères autorisés : Pas d'espace. Lettres et chiffres",
        PWRegex : "Caractères autorisés : Pas d'espace. Lettres et chiffres, - _ # @ :",
        chatWelcome : 'Bienvenue sur le chat.',
        whisps : ' chuchote',
        to : 'A',
        chatError_1 : "Cet utilisateur n'existe pas",
        chatError_2 : "Cet utilisateur n'est pas connecté",
        chatError_3 : "Commande incorrecte. Usage : /w [nom du destinataire] [message]",
        chatError_4 : "Vous ne pouvez pas vous envoyer de message privé à vous-même",
        me : 'Moi',
        seeProfile : 'Voir le profil',
        noProfile : 'Ce profil n\'existe pas',
        sendWhisp : 'Envoyer un message privé',
        leaveAComment : 'Laisser un commentaire',
        send : 'Envoyer',
        somethingWentWrong : 'Une erreur est survenue',
        edited : 'Edité le',
        delete : "Supprimer",
        areYouSure : "T'es sûr(e) ?",
        cancel : 'Annuler',
        seeAnswers : 'Voir les réponses',
        hideAnswers : 'Cacher les réponses',
        answer : 'Répondre',
        displayComments : 'Afficher les commentaires',
        edit : 'Editer',
        by : 'Par',
        commentPlaceholder : "Options de mise en forme :\n\n[quote=<Nom>]<Texte>[/quote] pour une citation\n[image=<source>] pour une image\n[center]<Contenu>[/center] pour centrer du contenu\n[snippet]<Code>[/snippet] pour insérer un snippet\n[i]<Texte>[/i] pour l'italique\n[b]<Texte>[/b] pour le gras\n(Les liens sont automatiquement mis en forme)",
        visits : 'Visites',
        users : 'Profils enregistrés',
        commentLoggedOut : "Vous devez vous connecter pour commenter",
        setLanguageError : "Une erreur est survenue :\n\nLa langue de votre profil n'a pas été modifiée",
        setLanguageError : "Une erreur est survenue :\n\nLe mode nuit de votre profil n'a pas été modifié",
        chooseFile : "Choisir un fichier",
        modifyAvatar : "Modifier l'avatar",
        avatarOkay : "Cela vous convient-il?",
        yes : "Oui",
        no : "Non"
    }
}

function English() {
    return {
        language : 'en',
        home : 'Home',
        projects : 'Projects',
        currentProject : 'Current project',
        progress : 'Completion',
        noPage : 'This page does not exist. Please check url and try again.',
        dev : 'Developer',
        signIn : 'Sign in',
        logout : 'Log out',
        signUp : 'Sign up',
        contact : 'Contact informations',
        created : 'Created on',
        poll : 'Poll',
        pollAsk : 'What should I do next ?',
        suggest : 'A new idea ?',
        suggestHead : 'You have a project idea to submit ? Or a challenge for me ? Or a comment about this website ? Here is where you can describe it.',
        suggestTitle : 'A catchy title',
        suggestDetails : 'A brilliant concept',
        suggestSend : 'Suggest',
        suggestLoggedOut : 'You need to be logged in to submit an idea',
        alreadySuggested : 'You already suggested something today',
        chatOn : 'Say something nice',
        chatOff : 'Log in to use the chat',
        trad : "And by the end of the next summer, this website came to be. Using the same stack we used on the famous last project of the school, I could - and still can, because one can always do better - let my creativité roam free.",
        bio1 : 'My name is Gilles Poncelet. And this is my website.',
        bio2 : 'I am born on the 13th of January 1989, in Belgium.',
        bio3 : 'Born in a family of artists, I soon got interested in music and theater.',
        bio4 : 'After my graduation, I naturally headed towards the Royal Conservatory of Brussels, where I got a master\'s degree in theater, which I completed with a teacher certification.',
        bio5 : 'In 2017, I created my own theater company, which I led for 3 years.',
        bio6 : 'During that time, I created a website for the company, using WordPress tools. But I sensed I could do better if I understood the gears of website creation.',
        bio7 : 'Then, shortly after I left the company, and while I was wondering what to do next, a friend told me I should give the code a serious try.',
        bio8 : 'This is how, in 2022, I joined the School 19, in Brussels. There, I spent 2 years learning how to code.',
        bio9 : 'At the very end of the school\'s program, I could try myself on a web project.',
        bio10 : 'And by the end of the next summer, this website came to be. Using the same stack we used on the famous last project of the school, I could - and still can, because one can always do better - let my creativity roam free.',
        noProject : 'This project does not exist. Check url and try again.',
        seeOnGH : 'See on GitHub',
        username : 'Username',
        password : 'Password',
        passwordConfirm : 'Password confirmation',
        connexion : 'Log in',
        signInError : 'Wrong username or password',
        createAccount : 'Create an account',
        allFieldsMandatory : 'All fields are mandatory',
        noAccount : 'No account',
        signUpError_1 : 'The email address format is incorrect',
        signUpError_2 : 'Passwords do not match',
        signUpError_3 : 'An account associated to this email address already exists',
        signUpError_4 : 'This username is not available',
        signUpError_5 : 'All fields are mandatory',
        signUpError_6 : 'An error occured while creating the account',
        signUpError_7 : 'An error occured during the authentification process',
        signUpError_8 : 'An error occured',
        signInError_1 : 'You are already logged in',
        signInError_2 : 'Incorrect username or password',
        signInError_3 : 'An error occured',
        hello : 'Hello,',
        profile : 'Profile',
        goAway : 'You shouldn\'t be here',
        suggestionSent : 'Suggestion sent',
        suggestionNotSent_1 : 'You need to be logged in to make a suggestion',
        suggestionNotSent_2 : 'You have an ongoing suggestion',
        mailMe : "Send me an e-mail",
        displayWidgets : 'Display widgets',
        invalidUsername : "Invalid username",
        invalidPW : 'Invalid password',
        nameRegex : "Accepted characters : No whitespace. Letters and digits",
        PWRegex : "Accepted characters : No whitespace. Letters and digits, - _ # @ :",
        chatWelcome : 'Welcome in the chat.',
        whisps : ' whisps',
        to : 'To',
        chatError_1 : "That user does not exist",
        chatError_2 : "That user is not connected",
        chatError_3 : "Incorrect command. Use : /w [target name] [message]",
        chatError_4 : "You cannot send a private message to yourself",
        me : 'Moi',
        seeProfile : 'See profile',
        noProfile : 'This profile does not exist',
        sendWhisp : 'Send a private message',
        leaveAComment : 'Leave a comment',
        send : 'Send',
        somethingWentWrong : 'Something went wrong',
        edited : 'Edited on',
        delete : "Delete",
        areYouSure : "Are you sure ?",
        cancel : 'Cancel',
        seeAnswers : 'See answers',
        hideAnswers : 'Hide answers',
        answer : 'Answer',
        displayComments : 'Display comments',
        edit : 'Edit',
        by : 'By',
        commentPlaceholder : "Display options :\n\n[quote=<Name>]<Text>[/quote] for a quote\n[image=<source>] for an image\n[center]<Content>[/center] to center content\n[snippet]<Code>[/snippet] to insert a snippet\n[i]<Text>[/i] for italicized\n[b]<Text>[/b] for bold\n(Links are automatically formated)",
        visits : 'Visits',
        users : 'Subscribed profiles',
        commentLoggedOut : "You need to be logged in to comment",
        setLanguageError : "An error occured :\n\nYour profile's language has not been modified",
        setLanguageError : "An error occured :\n\nYour profile's night mode has not been modified",
        chooseFile : "Choose a file",
        modifyAvatar : "Modify the avatar",
        avatarOkay : "Is that alright?",
        yes : "Yes",
        no : "No"
    }
}